import React, { useState } from "react";
import "./Sidebar.css";
import Image from "react-bootstrap/Image";

import { useLocation, NavLink, useNavigate, Form } from "react-router-dom";

function AppSidebar({ isOpen, toggleSidebar }) {
    const location = useLocation();
    const isActive = (path) => {
        return location.pathname === path ? "active-page" : "";
    };

    const handlePostRequest = async () => {
        // if (sessionStorage.getItem("wp_loger_id") && sessionStorage.getItem("wp_loger_id") == sessionStorage.getItem("loger_id")) {
        //     alert(1);
        // } else {
        //     alert(2);
        // }
        // if (sessionStorage.getItem("wp_loger_id") && sessionStorage.getItem("wp_loger_id") == sessionStorage.getItem("loger_id")) {
        //     location.href = "https://emstylemsk.net/shop/product/";
        // } else {
        //     document.getElementById("redirect").submit();
        // }

        document.getElementById("redirect").submit();
    };

    return (
        <>
            <div>
                <form id="redirect" action="https://emstylemsk.net/shop/my-account/" method="POST" target="_top">
                    <input type="hidden" name="username" value={sessionStorage.getItem("prov_email")} />
                    <input type="hidden" name="password" value={sessionStorage.getItem("prov_password")} />
                    {/* <button type="submit">Submit</button> */}
                </form>
            </div>
            <div className={`app-sidebar ${isOpen ? "open" : ""}`}>
                <div className={`logo text-center ${isOpen ? "hidden-sidebar-logo" : ""}`}>
                    {/* <img className="img-fluid" width={120} src="images/logo-light.png" alt="" /> */}
                    <Image src="../images/logo-light.png" width={150} className="img-fluid mx-auto d-block" />
                </div>
                <button className="close-btn" onClick={toggleSidebar}>
                    &times;
                </button>
                <div className="app-menu">
                    <ul className="accordion-menu">
                        <li className="sidebar-title">Menu</li>
                        {/* <li className={isActive("/Provider/Dashboard")}>
                        <NavLink to="/Provider/Dashboard">
                            <i className="fa-solid fa-gauge" />
                            Dashboard
                        </NavLink>
                    </li> */}
                        <li className={isActive("/Provider/PatientList") || isActive("/Provider/PatientView")}>
                            <NavLink to="/Provider/PatientList">
                                <i className="fa-solid fa-user-group" />
                                Patients
                            </NavLink>
                        </li>
                        {/* <li className={isActive("/Provider/Support")}>
                            <NavLink to="/Provider/Support">
                                <i className="fa-solid fa-headset" /> Support
                            </NavLink>
                        </li> */}
                        {/* <li className={isActive("/Provider/Payment")}>
                            <NavLink to="/Provider/Payment">
                                <i className="fa-solid fa-calculator ms-1" /> Payments &amp; Audit
                            </NavLink>
                        </li> */}
                        {/* <li className={isActive("/Provider/ProductList") || isActive("/Provider/ProductAdd")}>
                            <NavLink to="/Provider/ProductList">
                                <i className="fa-solid fa-user-group" />
                                Product
                            </NavLink>
                        </li> */}
                        <li className={isActive("/shop/product/")}>
                            {/* <NavLink to="https://emstylemsk.net/shop/product/"> */}
                            {/* <a href="https://emstylemsk.net/shop/product/">
                                <i className="fa-solid fa-cart-shopping"></i>
                                Shop
                            </a> */}
                            {/* <NavLink onClick={handlePostRequest}>
                                <i className="fa-solid fa-cart-shopping"></i>
                                Shop
                            </NavLink> */}

                            {/* <NavLink to="/shop/product/">
                                <i className="fa-solid fa-cart-shopping"></i> Shop
                            </NavLink> */}
                            <a href="https://emstylemsk.net/shop/product/" rel="noopener noreferrer">
                                <i className="fa-solid fa-cart-shopping"></i>
                                Shop
                            </a>
                        </li>
                        {/* <li className={isActive("/Provider/ClinicalList") || isActive("/Provider/ClinicalAdd") || isActive("/Provider/ClinicalView")}>
                            <NavLink to="/Provider/ClinicalList">
                                <i className="fa-solid fa-house-chimney-medical" /> Clinical
                            </NavLink>
                        </li> */}
                        <li className={isActive("/Provider/Settings")}>
                            <NavLink to="/Provider/Settings">
                                <i className="fa-solid fa-gear" /> Settings
                            </NavLink>
                        </li>
                        <li className={isActive("/Provider/Profile")}>
                            <NavLink to="/Provider/Profile">
                                <i className="fa-solid fa-user" /> Profile
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
}

export default AppSidebar;
